import { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import {
//   requestFetchConversation,
//   requestFetchConversationDetail,
// } from "../store/actions"
import { updatePaginate } from "core/store/actions";
import { parseFilterSelect } from "core/utils/helper";
import { change } from "redux-form";
import {
  requestFetchChartStatistic,
  requestFetchMerchantStatistic,
  requestFetchPostStatistic,
  requestFetchStatistic,
  requestFetchTotalStatistic,
  requestFetchUserStatistic,
} from "../store/actions";
import moment from "moment";

export const formNameStatistics = "statisticsMgmt";
export const formNameTotalStatistics = "totalStatisticsMgmt";
export const formNameChartStatistics = "chartStatisticsMgmt";
export const formNameUseStatistic = "userStatisticMgmt";
export const formNameMerchantStatistic = "merchantStatisticMgmt";
export const formNamePostStatistic = "postStatisticMgmt";
export const OPTION_CHART = [
  { value: 'day', label: 'Ngày' },
  { value: 'month', label: 'Tháng' },
]
export const useStatisticParam = (query: any) => {
  console.log('query', query.get('chart_option'));
  
  const filterRedux = useSelector(
    (state: any) => state.form[formNameStatistics]?.values
  );
  const paginateUserRedux = useSelector(
    (state: any) => state.paginate[formNameUseStatistic]
  );

  const paginateMerchantRedux = useSelector(
    (state: any) => state.paginate[formNameMerchantStatistic]
  );

  const paginatePostRedux = useSelector(
    (state: any) => state.paginate[formNamePostStatistic]
  );

  const reduxDispatch = useDispatch();
  const [param, setParam] = useState({
    startDate: query.get('startDate') || moment().subtract(6, "days").format("YYYY-MM-DD"),
    endDate: query.get('endDate') || moment().format("YYYY-MM-DD"),
    u_page: 1,
    u_page_size: 10,
    m_page: 1,
    m_page_size: 10,
    p_page: 1,
    p_page_size: 10,
    chart_option: query.get('chart_option') || 'day'
  });

  useEffect(() => {
    if (paginateUserRedux) {
      setParam((p: any) => ({
        ...(p || {}),
        u_page: +paginateUserRedux?.page || 1,
        u_page_size: +paginateUserRedux?.page_size || 10,
      }));
    }
  }, [paginateUserRedux]);

  useEffect(() => {
    if (paginateMerchantRedux) {
      setParam((p: any) => ({
        ...(p || {}),
        m_page: +paginateMerchantRedux?.page || 1,
        m_page_size: +paginateMerchantRedux?.page_size || 10,
      }));
    }
  }, [paginateMerchantRedux?.page, paginateMerchantRedux?.page_size]);

  useEffect(() => {
    if (paginatePostRedux) {
      setParam((p: any) => ({
        ...(p || {}),
        p_page: paginatePostRedux?.page || 1,
        p_page_size: paginatePostRedux?.page_size || 10,
      }));
    }
  }, [paginatePostRedux]);

  useEffect(() => {
    if (filterRedux) {
      console.log('filterRedux', filterRedux);
      console.log('parseFilterSelect(filterRedux?.chart_option)', parseFilterSelect(filterRedux?.chart_option));
      
      setParam((p: any) => ({
        ...(p || {}),
        startDate:
          filterRedux?.date?.startDate ||
          moment().subtract(7, "days").format("YYYY-MM-DD"),
        endDate: filterRedux?.date?.endDate || moment().format("YYYY-MM-DD"),
        chart_option: parseFilterSelect(filterRedux?.chart_option) || 'day',
        u_page: 1,
        m_page: 1,
        p_page: 1,
      }));
    }
  }, [filterRedux]);

  // clear query_value when query_field change
  useEffect(() => {
    reduxDispatch(updatePaginate(formNameUseStatistic, { page: 1 }));
    reduxDispatch(updatePaginate(formNameMerchantStatistic, {  page: 1  }));
    reduxDispatch(updatePaginate(formNamePostStatistic, {  page: 1  }));
  }, [filterRedux?.date?.startDate, filterRedux?.date?.endDate]);

  const handlePageChange = (page: number, formName: string) => {
    let _formNamePage: any = `${formName}_page`;
    console.log(118, _formNamePage, page);
    
    reduxDispatch(updatePaginate(formName, { [_formNamePage]: page }));
  };

  return {
    param,
    handlePageChange,
  };
};

export const useStatistic = (param: any) => {
  const reduxDispatch = useDispatch();
  const redux = useSelector((state: any) => state.Dashboard);

  const handleFetchStatistic = () => {
    reduxDispatch(requestFetchStatistic({}));
  };

  return [redux[formNameStatistics], handleFetchStatistic];
};

export const useTotalStatistic = (cb: any) => {
  const reduxDispatch = useDispatch();
  const redux = useSelector((state: any) => state.Dashboard);

  const fetchTotalStatistic = (param: any) => {
    reduxDispatch(
      requestFetchTotalStatistic({
        start_date: param.startDate,
        end_date: param.endDate,
      })
    );
    // reduxDispatch(
    //   requestFetchMerchantStatistic({
    //     start_date: param.startDate,
    //     end_date: param.endDate,
    //     page: param.m_page,
    //     page_size: param.m_page_size,
    //     order_by: 'created_at',
    //     order_dir: 'desc'
    //   })
    // );
    // reduxDispatch(
    //   requestFetchUserStatistic({
    //     start_date: param.startDate,
    //     end_date: param.endDate,
    //     page: param.u_page,
    //     page_size: param.u_page_size,
    //   })
    // );
  };

  return [redux[formNameTotalStatistics], fetchTotalStatistic];
};

export const useChartStatistic = (cb: any) => {
  const reduxDispatch = useDispatch();
  const redux = useSelector((state: any) => state.Dashboard);

  const handleFetchChartStatistic = (param: any) => {
    console.log('handleFetchChartStatistic', param);
    
    reduxDispatch(
      requestFetchChartStatistic({
        start_date: param.startDate,
        end_date: param.endDate,
        group_by: param.chart_option
      })
    );
  };

  return [redux[formNameChartStatistics], handleFetchChartStatistic];
};


export const useUserStatistic = (cb: any) => {
  const reduxDispatch = useDispatch();
  const redux = useSelector((state: any) => state.Dashboard);

  const handleFetchUserStatistic = (param: any) => {
    const _param = {
      start_date: param.startDate,
      end_date: param.endDate,
      page: param.u_page,
      page_size: param?.u_page_size || 10,
      order_by: 'created_at',
      order_dir: 'desc'
    };
    reduxDispatch(requestFetchUserStatistic(_param));
  };

  return [redux[formNameUseStatistic], handleFetchUserStatistic];
};

export const useMerchantStatistic = (cb: any) => {
  const reduxDispatch = useDispatch();
  const redux = useSelector((state: any) => state.Dashboard);

  const handleFetchMerchantStatistic = (param: any) => {
    const _param = {
      start_date: param.startDate,
      end_date: param.endDate,
      page: param.m_page,
      page_size: param.m_page_size,
      order_by: 'created_at',
      order_dir: 'desc'
    };
    reduxDispatch(requestFetchMerchantStatistic(_param));
  };

  return [redux[formNameMerchantStatistic], handleFetchMerchantStatistic];
};

export const usePostStatistic = (cb: any) => {
  const reduxDispatch = useDispatch();
  const redux = useSelector((state: any) => state.Dashboard);

  const handleFetchPostStatistic = (param: any) => {
    const _param = {
      start_date: param.startDate,
      end_date: param.endDate,
      page: param.p_page,
      page_size: param.p_page_size,
      order_by: 'created_at',
      order_dir: 'desc'
    };
    reduxDispatch(requestFetchPostStatistic(_param));
  };

  return [redux[formNamePostStatistic], handleFetchPostStatistic];
};

// export const useStatisticDetail = () => {
//   const { id } = useParams()
//   const reduxDispatch = useDispatch()
//   const redux = useSelector((state: any) => state.Chat)

//   useEffect(() => {
//     if (id) {
//       reduxDispatch(requestFetchConversationDetail(id))
//     }
//   }, [id])

//   return redux.conversation
// }
