import { useQuery } from "core/hooks/useQuery";
import DashboardLayout from "core/layouts/DashboardLayout";
import API from "core/networks/API";
import { useBackNavigate } from "core/utils/helper";
import UserChatTab from "modules/Pawn/components/UserChatTab";
import UserCouponTab from "modules/Pawn/components/UserCouponTab";
import UserPostTab from "modules/Pawn/components/UserPostTab";
import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { API_ADMIN_MERCHANT } from "../../api";
import "./../../assets/scss/user.scss";
import { fetchUserChat, fetchUserCoupon, fetchUserDetail, fetchUserPost } from "modules/Pawn/store/actions";

const reduxFuncDetailName = "userDetailMgmt";
const USER_TAB = [
	{
		value: "U_POST",
		reduxName: "uPostMgmt"
	},
	{
		value: "U_COUPON",
		reduxName: "uCouponMgmt"
	},
	{
		value: "U_CHAT",
		reduxName: "uChatMgmt"
	}];

const STATISTIC_ARR: any = 
	{
		U_POST: [
			{
				value: "total_post",
				label: "Total post",
				total: 20,
			},
			{
				value: "processing_post",
				label: "Processing post",
				total: 12,
			},
			{
				value: "confirm_post",
				label: "Confirmed post",
				total: 15,
			},
			{
				value: "completed_post",
				label: "Completed post",
				total: 11,
			},
			{
				value: "ended_post",
				label: "Ended post",
				total: 6,
			},
			{
				value: "cancel_post",
				label: "Canceled post",
				total: 2,
			},
		],
		U_COUPON: [
			{
				value: "total_coupon",
				label: "Total Coupon",
				total: 20,
			},
			{
				value: "processing_post",
				label: "Processing post",
				total: 12,
			},
			{
				value: "confirm_post",
				label: "Confirmed post",
				total: 15,
			},
			{
				value: "completed_post",
				label: "Completed post",
				total: 11,
			},
			{
				value: "ended_post",
				label: "Ended post",
				total: 6,
			},
			{
				value: "cancel_post",
				label: "Canceled post",
				total: 2,
			},
		],
		U_CHAT: [
			{
				value: "total_coupon",
				label: "Total Coupon",
				total: 20,
			},
			{
				value: "processing_post",
				label: "Processing post",
				total: 12,
			},
			{
				value: "confirm_post",
				label: "Confirmed post",
				total: 15,
			},
			{
				value: "completed_post",
				label: "Completed post",
				total: 11,
			},
			{
				value: "ended_post",
				label: "Ended post",
				total: 6,
			},
			{
				value: "cancel_post",
				label: "Canceled post",
				total: 2,
			},
		],
};

const UserDetail = (props: any) => {
  const commonRedux = useSelector((state: any) => state.Common);
  const navigate = useBackNavigate();
  const queryParams: any = useQuery();
	const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const params = useParams();
	const tabActive = searchParams.get('tab') || 'U_POST';
  const [key, setKey] = useState(tabActive);
  const [formLoading, setFormLoading] = useState(false);
  const [detailItem, setDetailItem] = useState<any>({});
  const handleGoBack = () => {
    navigate();
  };
	const paramsObj = Object.fromEntries([...searchParams]);
	
	useEffect(() => {
		setSearchParams({
			...paramsObj,
			tab: tabActive,
			statistic: STATISTIC_ARR[tabActive][0].value
		})
	}, [])

	const changeTab = (k: any) => {
		setKey(k);
		setSearchParams({
			...paramsObj,
			tab: k,
			statistic: STATISTIC_ARR[k][0].value
		})
	}

  const handleChangeMerchantState = async (e: any) => {
    try {
      const res = await API.put(API_ADMIN_MERCHANT + "/" + params.id, {
        state: e.value,
      });
      //console.log(res)
      toast.success("Merchant state updated successfully!");
    } catch (e: any) {
      //console.log(e)
      toast.error("Merchant state update failed!");
    }
  };

  useEffect(() => {
		dispatch(fetchUserDetail(reduxFuncDetailName, 'GET', API_ADMIN_MERCHANT, paramsObj))
  }, []);

	useEffect(() => {
		const _redux: any = USER_TAB.find((item: any) => item.value === paramsObj.tab)
		console.log(_redux);
		
		switch (paramsObj.tab) {
			case USER_TAB[0].value:
				dispatch(fetchUserPost(_redux?.reduxName, 'GET', API_ADMIN_MERCHANT, paramsObj))
				break;
			case USER_TAB[1].value:
				dispatch(fetchUserCoupon(_redux?.reduxName, 'GET', API_ADMIN_MERCHANT, paramsObj))
				break;
			case USER_TAB[2].value:
				dispatch(fetchUserChat(_redux?.reduxName, 'GET', API_ADMIN_MERCHANT, paramsObj))
				break;
			default:
				break;
		}
	}, [paramsObj])

  return (
    <>
      <DashboardLayout currentPage="dashboard/users" title="User management">
        <h1>
          <span className="go-back" onClick={handleGoBack}>
            <i className="fa-solid fa-chevron-left"></i> Quay lại
          </span>{" "}
          User detail
        </h1>
        <div className="card mt-4 mb-4 user-detail">
          <div className="card-body">
            <table className="table table-bordered table-form">
              <tr>
                <th style={{ width: "15%" }}>User ID</th>
                <td style={{ width: "20%" }}>
                  {detailItem?.merchant?.username}
                </td>
                <th style={{ width: "15%" }}>Phone number</th>
                <td style={{ width: "20%", padding: "0" }}>
                  {detailItem.state && (
                    <Select
                      closeMenuOnSelect={true}
                      options={[
                        { value: "ACTIVED", label: "Active" },
                        { value: "INACTIVED", label: "Inactive" },
                      ]}
                      defaultValue={{
                        value: detailItem.state,
                        label:
                          detailItem.state === "INACTIVED"
                            ? "Inactive"
                            : "Active",
                      }}
                      onChange={handleChangeMerchantState}
                    />
                  )}
                </td>
                <th style={{ width: "15%" }}>Region</th>
                <td>VN</td>
              </tr>
              <tr>
                <th>Name</th>
                <td>{detailItem?.merchant?.name}</td>
                <th>Gender</th>
                <td></td>
                <th>Age (DoB)</th>
                <td></td>
              </tr>
              <tr>
                <th>Register at</th>
                <td>{detailItem?.merchant?.phone}</td>
                <th>Last login at</th>
                <td></td>
                <th>Status</th>
                <td>
                  <Select
                    closeMenuOnSelect={true}
                    options={[
                      { value: "ACTIVED", label: "Active" },
                      { value: "INACTIVED", label: "Inactive" },
                    ]}
                    defaultValue={{
                      value: detailItem.state,
                      label:
                        detailItem.state === "INACTIVED"
                          ? "Inactive"
                          : "Active",
                    }}
                    onChange={handleChangeMerchantState}
                  />
                </td>
              </tr>
            </table>
            <br />
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k: any) => changeTab(k)}
              className="mb-3 user-detail-tab"
            >
              <Tab eventKey={USER_TAB[0].value} title="User's posts">
								<UserPostTab queryParams={queryParams} dataStatistic={STATISTIC_ARR[tabActive]} />
              </Tab>
              <Tab eventKey={USER_TAB[1].value} title="User's coupon history">
                <UserCouponTab queryParams={queryParams} dataStatistic={STATISTIC_ARR[tabActive]}/>
              </Tab>
              <Tab eventKey={USER_TAB[2].value} title="User's chat conversation">
                <UserChatTab queryParams={queryParams} dataStatistic={STATISTIC_ARR[tabActive]}/>
              </Tab>
            </Tabs>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export default UserDetail;
