import DashboardLayout from "core/layouts/DashboardLayout"
import DataSet from "core/components/DataSet"
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {fetchPawnDataList, initPawnDataList} from "../../store/actions";
import {parseFilterSelect} from "core/utils/helper";
import UserListRowTemplate from "../../components/UserListRowTemplate";
import API from "../../../../core/networks/API";
import { API_ADMIN_MERCHANT } from "modules/Pawn/api";
import { useQuery } from "core/hooks/useQuery";
import UserFilter from "./UserFilter";
import moment from "moment";

let reduxFuncName = 'userMgmt'
const PageListPage = (props: any) => {
    const dispatch = useDispatch()
    const filterRedux = useSelector((state: any) => state.form[reduxFuncName]?.values)
    const paginateRedux = useSelector((state: any) => state.paginate[reduxFuncName])
    const queryParams = useQuery()

    const parseFilter = () => {
        let filter = {...filterRedux}
        filter.status = parseFilterSelect(filter.status)
        //console.log({...filter, ...paginateRedux})
        return {...filter, ...paginateRedux}
    }

    const callFetchData = () => {
        dispatch(fetchPawnDataList(reduxFuncName, 'GET', API_ADMIN_MERCHANT, parseFilter()))
    }

    const callDeleteData = async (ids: any) => {
        try{
            await API.delete("/cms/v1/content/page", {ids})
            callFetchData()
        } catch(e: any){
            console.error(e)
        }
    }

    const handleChangeFilter =(data: any) => {
        callFetchData()
    }

    const handleBulkDelete = (ids: any) => {
        //console.log(32, ids)
        callDeleteData(ids)
    }

    useEffect(() => {
        if(paginateRedux) {
            callFetchData()
        }
    }, [paginateRedux]);

    const getDefaultFilterValue = () => {
        return {
            status: queryParams.get("status") || "",
            keyword: queryParams.get("keyword"),
            date: {
                startDate: queryParams.get("startDate") || moment().format("YYYY-MM-DD"),
                endDate: queryParams.get("endDate") || moment().format("YYYY-MM-DD")
            }
        }
    }

    useEffect(() => {
        dispatch(initPawnDataList(reduxFuncName))
    }, []);

return <>
        <DashboardLayout currentPage="dashboard/users" title="User management">
            <h1>USERS MANAGEMENT</h1>
            <UserFilter
                    onSubmit={handleChangeFilter}
                    initialValues={getDefaultFilterValue()}/>
            {/* <DataSet
                filterName={reduxFuncName}
                columnKey={reduxFuncName}
                pagination={{page: 1, page_size: 20}}
                columnTemplate={[
                    {label: "Trạng thái", key: "state", state: true, sort: false},
                    {label: "Tiêu đề", key: "title", state: true, sort: false},
                    // {label: "Người tạo", key: "created_by", state: true, sort: false},
                    {label: "Tạo lúc", key: "created_at", state: true, sort: false},
                    // {label: "Người chỉnh sửa", key: "updated_by", state: true, sort: false},
                    {label: "Ngày chỉnh sửa", key: "updated_at", state: true, sort: false},
                ]}
                onDelete={handleBulkDelete}
                rowTemplate={UserListRowTemplate}
            /> */}
            <DataSet
                idKey="hidden"
                filterName={reduxFuncName}
                module={'Pawn'}
                columnKey={reduxFuncName}
                pagination={{page: queryParams.get("page") || 1, page_size: queryParams.get("page_size") || 20}}
                columnTemplate={[
                    {label: "Sign up at", key: "mc_username", state: true, sort: false},
                    {label: "Status", key: "mc_phone", state: true, sort: false},
                    {label: "Phone", key: "shop_name", state: true, sort: false},
                    // {label: "Shop licence", key: "biz_lcnc_path", state: true, sort: false},
                    {label: "Name", key: "shop_addr_1", state: true, sort: false},
                    {label: "Gender", key: "mc_created_at", state: true, sort: false},
                    {label: "Age (DoB)", key: "mc_state", state: true, sort: false},
                    {label: "Last login at", key: "mc_state", state: true, sort: false},
                    {label: "Total post", key: "mc_state", state: true, sort: false},
                ]}
                rowTemplate={UserListRowTemplate}
            />
        </DashboardLayout>
    </>
}

export default PageListPage