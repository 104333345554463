import {useEffect} from "react";
import moment from "moment";
import {formatDateTime, highlightKeywords, useCustomNavigate} from "../../../core/utils/helper";
import {RouterName} from "../../../core/router";
import {useSelector} from "react-redux";

const UserStatisticListRowTemplate = (props: any) => {
    const navigate = useCustomNavigate()
    const filterRedux = useSelector((state: any) => state.form[props.filterName]?.values)
    //console.log(filterRedux)
    useEffect(() => {
    }, []);

    useEffect(() => {
    }, [props]);

    const renderCell = (key: any) => {
        switch (key) {
            case 'id':
                return <>#{props.data.id}</>
            case 'phone':
                return <>{props.data.phone}</>
            case 'display_name':
                return <>{props.data.display_name}</>
            case 'created_at':
                return <>{formatDateTime(props.data.created_at)}</>
            default:
                return <></>
        }
    }

    const handlePreview = () => {
        window.open(`/${props.data?.slug?.alias}.html`, '_blank')
    }
    const handleEdit = () => {
        window.open(`https://api.pawnmarket.enjoyworks.co.kr/statistic`, '_blank')
    }
    const handleDelete = () => {
        if(props.onDelete) {
            props.onDelete([props.data.id])
        }
    }

    return <tr onDoubleClick={handleEdit}>
        {
            props.columns.length > 0 && props.columns.map((col: any, k: number) => {
                if(col.state) return <td key={k}>{renderCell(col.key)}</td>
            })
        }
    </tr>
}
export default UserStatisticListRowTemplate