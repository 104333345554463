import moment from "moment";
import slugify from "slugify";
import { config } from "../config/app";
import { deviceStorage } from "./devicestorage";
import _ from "lodash";
import {AppRouter, route} from "../router";
import {useNavigate} from "react-router-dom";
import store from "../store/store";
import { regions } from "./region";

// export const numberFormat = (number: number | string, fixed: number = 0, showFull = false) => {
//     let num
//     if (typeof number === "string") {
//         num = parseFloat(number)
//     } else {
//         num = number
//     }
//     if (num < 100000000 || showFull) {
//         let formattedNumber = num.toLocaleString('en-US', { minimumFractionDigits: fixed, maximumFractionDigits: fixed }); // "123,456,789.12"
//         return formattedNumber
//     } else {
//         let mNum = num / 1000000
//         let formattedNumber = mNum.toLocaleString('en-US', { minimumFractionDigits: fixed, maximumFractionDigits: fixed }); // "123,456,789.12"
//         return formattedNumber + "M"
//     }
//
// }
export const highlightKeywords = (str: string, keyword: string) => {
    if(keyword && str) {
        // Chuyển chuỗi keyword thành một mảng các từ khóa
        const keywords = keyword.split(',');
        //console.log(keywords)
        // Duyệt qua từng từ khóa
        keywords.length > 0 && keywords.forEach(key => {
            // Tạo biểu thức chính quy để tìm từ khóa trong chuỗi
            const regex = new RegExp(key.trim(), 'gi');
            // Thay thế các từ khóa trong chuỗi bằng cùng từ khóa được bọc trong thẻ <mark> để highlight
            str = str.replace(regex, `<mark>${key.trim()}</mark>`);
        });
    }

    return str;
}
export const numberFormat = (number: number | string, thousandDigit: string = ',', fixed: number = 2): string => {
    // Kiểm tra xem đầu vào có phải là số hay không
    const num: number = typeof number === 'string' ? parseFloat(number) : number;
    if (isNaN(num)) {
        return "Invalid number input";
    }

    // Kiểm tra số âm để xử lý định dạng
    const isNegative: boolean = num < 0;
    const absNum: number = Math.abs(num);

    // Chia thành phần nguyên và thập phân
    const intPart: number = Math.floor(absNum);
    const decimalPart: string = (absNum - intPart).toFixed(fixed).slice(2); // Loại bỏ số 0 trước dấu chấm

    // Xác định dấu phân cách hàng nghìn và dấu thập phân
    const separator: string = thousandDigit
    const decimalSeparator: string = thousandDigit === '.' ? ',' : '.';

    // Định dạng phần nguyên với ký tự phân biệt hàng nghìn
    let formattedIntPart: string = intPart.toLocaleString().replace(/,/g, separator);

    // Xử lý trường hợp dấu phân cách hàng nghìn nằm ở cuối số hàng đơn vị

    if (formattedIntPart.endsWith(thousandDigit)) {
        formattedIntPart = formattedIntPart.slice(0, -1);
    }

    // Kết hợp các phần đã định dạng và xử lý số âm
    let formattedNumber: string
    if(absNum - intPart > 0) formattedNumber = `${formattedIntPart}${decimalSeparator}${decimalPart}`;
    else formattedNumber =  `${formattedIntPart}`
    if (isNegative) {
        formattedNumber = `-${formattedNumber}`;
    }

    return formattedNumber;
}

export const documentTitle = (str: string) => {
    document.title = str;
}

export const convertToSlug = (text: string) => {
    return slugify(text, {
        replacement: "-", // Sử dụng dấu gạch ngang thay thế khoảng trắng và các ký tự đặc biệt
        remove: /[*+~.()'"!:@]/g, // Loại bỏ các ký tự đặc biệt
        lower: true, // Chuyển đổi thành chữ thường
    });
}


export const stringLimit = (string: string | undefined, limit: number = 30) => {
    if (string != undefined && string.length > limit) {
        return `${string.substring(0, limit)}...`
    } else {
        return string
    }
}


export const useCustomNavigate = () => {
    const navigate = useNavigate();

    const handleNavigation = (path: string) => {
        navigate(path);
    };

    return handleNavigation;
};

export const useBackNavigate = () => {
    const navigate = useNavigate();

    const handleNavigation = (path: string|undefined = undefined) => {
        if(path) navigate(path);
        else navigate(-1)
    };

    return handleNavigation;
};


export function formatDateTime(dateTime: any, format = 'YYYY-MM-DD HH:mm:ss') {
    // ////console.log(locale);
    if (!dateTime) return '--';
    const timezone = "UTC+7"
    return moment(dateTime).add(timezone.replace(/^\D+/g, ''), 'hours').format(format)
    // return moment(dateTime).format(format);
}

export const parseFilterSelect = (set: any) => {
    try {
        const statusValues = JSON.parse(set)
        if (Array.isArray(statusValues)) {
            let status: any = []
            statusValues.forEach((item: any) => {
                if (item.value && item.value != 'ALL') status.push(item.value)
            })
            return status.join(',')
        } else {
            return statusValues.value
        }
    } catch(e: any){
        // console.error(e)
        return set;
    }
}

export const setParamsUrl = (params: any) => {
    // //console.log(153, params)
    let urlParams: string[] = [];
    Object.keys(params).forEach((k) => {
        if(["number", "string"].includes(typeof params[k])){
            //console.log(156, params[k])
            urlParams.push(k+"="+params[k])
        }
    })
    // //console.log(161, "?"+urlParams.join("&"))
    window.history.pushState({}, '', "?"+urlParams.join("&"));
    // window.location.replace("?"+urlParams.join("&"))
    // return "?"+urlParams.join("&")
}

export const getImageWithName = (name: string) => {
    name = name[0]
    
    return `https://api.dicebear.com/5.x/initials/svg?seed=${name}`
}


export const getAreaInfo = (locId: number|undefined, cityId: number|undefined, districtId: number|undefined, wardId: number|undefined) => {
    let city:any, district:any, ward: any
    city = _.find(regions['VN'], { 'id': cityId });

    if(city){
        district = _.find(city.districts, { 'id': districtId });
        if(district){
            ward = _.find(district.wards, { 'id': wardId });
        }
    }
    let address: string[] = [];
    if(ward) address.push(ward.name)
    if(district) address.push(district.name)
    if(city) address.push(city.name)
    return address.join(', ')
}