import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

type StatisticTabProps = {
  data: any;
  queryParams: any
}

const StatisticTab = ({data, queryParams}: StatisticTabProps) => {
  const paramsObj = Object.fromEntries([...queryParams]);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleChange = (value: any) => {
    setSearchParams({
			...paramsObj,
			statistic: value
		})
  };

  return (
    <div className="col-2">
      <ul className="mt-4 list-group-flush">
        {data.map((item: any) => (
          <li
            className={`list-group-item mb-2 ${
              item.value === queryParams.get("statistic") ? "active" : ""
            }`}
            onClick={() => handleChange(item.value)}
          >
            {item.label} ({item.total})
          </li>
        ))}
      </ul>
    </div>
  )

}

export default StatisticTab;