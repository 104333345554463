import DataSet from "core/components/DataSet";
import StatisticTab from "./StatisticTab";
import UserCouponTabRowTemplate from "./UserCouponTabRowTemplate";
import KDialog from "core/components/Dialog/KDialog";
import { useEffect, useState } from "react";
import API from "core/networks/API";
import { API_COUPON_LIST } from "../api";

type UserCouponTabProps = {
  queryParams: any;
  dataStatistic: any;
};
let reduxFuncName = "userCouponTabMgmt";
const UserCouponTab = ({ queryParams, dataStatistic }: UserCouponTabProps) => {
  const [issueDialog, setIssueDialog] = useState(false);
  const [issueData, setIssueData] = useState({
    phone: "",
    couponId: "",
  });
  const [issueError, setIssueError] = useState({
    phone: "",
    couponId: "",
  });
  const [coupons, setCoupon] = useState<any>([]);
  const handleSubmitIssueForm = () => {};

  const handleChangeIssuePhone = (e: any) => {
    setIssueData((p: any) => ({ ...p, phone: e.target.value }));
  };

  const handleChangeIssueCoupon = (e: any) => {
    setIssueData((p: any) => ({ ...p, couponId: e.target.value }));
  };

  const callFetchCouponList = async () => {
    try {
      const res = await API.get(API_COUPON_LIST, {
        state: "ENABLED",
        page_size: 0,
      });
      //console.log(79, res)
      if (res.data?.data) {
        setCoupon(res.data.data);
        setIssueData((p: any) => ({
          ...p,
          couponId: res.data.data[0]?.id || "",
        }));
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    callFetchCouponList();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col text-end">
          <button
            type="button"
            className="btn btn-search me-2 ms-5"
            onClick={() => {
              setIssueDialog(true);
            }}
          >
            {" "}
            + Cấp coupon
          </button>
        </div>
      </div>
      <div className="row">
        <StatisticTab data={dataStatistic} queryParams={queryParams} />
        <div className="col-10">
          <DataSet
            idKey="hidden"
            filterName={reduxFuncName}
            module={"Pawn"}
            columnKey={reduxFuncName}
            pagination={{
              page: queryParams.get("page") || 1,
              page_size: queryParams.get("page_size") || 20,
            }}
            columnTemplate={[
              {
                label: "Coupon code",
                key: "mc_username",
                state: true,
                sort: false,
              },
              { label: "Issued by", key: "mc_phone", state: true, sort: false },
              {
                label: "Issued at",
                key: "shop_name",
                state: true,
                sort: false,
              },
              {
                label: "Expired at",
                key: "biz_lcnc_path",
                state: true,
                sort: false,
              },
              {
                label: "Validated at",
                key: "shop_addr_1",
                state: true,
                sort: false,
              },
              {
                label: "Activated at",
                key: "mc_created_at",
                state: true,
                sort: false,
              },
              {
                label: "Used at",
                key: "mc_state",
                state: true,
                sort: false,
              },
              {
                label: "Confirmed at",
                key: "mc_state",
                state: true,
                sort: false,
              },
              { label: "State", key: "mc_state", state: true, sort: false },
              { label: "Post", key: "mc_state", state: true, sort: false },
            ]}
            rowTemplate={UserCouponTabRowTemplate}
          />
        </div>
      </div>
      <KDialog
        variant={"warning"}
        icon={<i className="fa-solid fa-ticket"></i>}
        title={"Issue coupon"}
        show={issueDialog}
        overlay={true}
        onCancel={() => {
          // setIssueData({phone: "", couponId: coupons[0]?.id || ''})
          // setIssueError({phone: "", couponId: ""})
          setIssueDialog(false);
        }}
        cancelBtn={"Cancel"}
        onSubmit={handleSubmitIssueForm}
        submitBtn={"Confirm"}
      >
        <div className="row mb-3 custom-input">
          <div className="col-sm-12">
            <label>User Phone:</label>
            <input
              onChange={handleChangeIssuePhone}
              name={"phone"}
              className="form-control"
              placeholder={"Please enter the user phone number"}
            />
            {issueError.phone && (
              <p className="error ps-2">{issueError.phone}</p>
            )}
          </div>
        </div>
        <div className="row mb-3 custom-input">
          <div className="col-sm-12">
            <label>Select Coupon:</label>
            <select className="form-control" onChange={handleChangeIssueCoupon}>
              {coupons.map((coupon: any) => (
                <option value={coupon.id}>{coupon.name}</option>
              ))}
            </select>
          </div>
        </div>
      </KDialog>
    </>
  );
};

export default UserCouponTab;
