import {FETCH_PAWN_LIST, FETCH_PAWN_LIST_FAIL, FETCH_PAWN_LIST_SUCCESS, FETCH_USER_CHAT, FETCH_USER_CHAT_FAILED, FETCH_USER_CHAT_SUCCESS, FETCH_USER_COUPON, FETCH_USER_COUPON_FAILED, FETCH_USER_COUPON_SUCCESS, FETCH_USER_DETAIL, FETCH_USER_DETAIL_FAILED, FETCH_USER_DETAIL_SUCCESS, FETCH_USER_POST, FETCH_USER_POST_FAILED, FETCH_USER_POST_SUCCESS, INIT_PAWN_LIST} from "./types";


const PawnReducer = (
    state: any = {},
    action: any,
): any => {
    switch (action.type) {
        case INIT_PAWN_LIST:
            return {...state, [action.storeName]: {list: {}, loading: false}}
        case FETCH_PAWN_LIST:
            return {...state, [action.storeName]: {list: {}, loading: true}}
        case FETCH_PAWN_LIST_SUCCESS:
            return {...state, [action.storeName]: {...action.payload, loading: false}}
        case FETCH_PAWN_LIST_FAIL:
            return {...state, [action.storeName]: {list: {}, loading: false}}
        case FETCH_USER_DETAIL:
            return {...state, [action.storeName]: {data: {}, loading: true}}
        case FETCH_USER_DETAIL_SUCCESS:
            return {...state, [action.storeName]: {...action.payload, loading: false}}
        case FETCH_USER_DETAIL_FAILED:
            return {...state, [action.storeName]: {data: {}, loading: false}}
        case FETCH_USER_POST:
            return {...state, [action.storeName]: {list: {}, loading: true}}
        case FETCH_USER_POST_SUCCESS:
            return {...state, [action.storeName]: {...action.payload, loading: false}}
        case FETCH_USER_POST_FAILED:
            return {...state, [action.storeName]: {list: {}, loading: false}}
        case FETCH_USER_COUPON:
            console.log(action.storeName, 'action.storeName');
            
            return {...state, [action.storeName]: {list: {}, loading: true}}
        case FETCH_USER_COUPON_SUCCESS:
            return {...state, [action.storeName]: {...action.payload, loading: false}}
        case FETCH_USER_COUPON_FAILED:
            return {...state, [action.storeName]: {list: {}, loading: false}}
        case FETCH_USER_CHAT:
            return {...state, [action.storeName]: {list: {}, loading: true}}
        case FETCH_USER_CHAT_SUCCESS:
            return {...state, [action.storeName]: {...action.payload, loading: false}}
        case FETCH_USER_CHAT_FAILED:
            return {...state, [action.storeName]: {list: {}, loading: false}}
        default:
            return state;
    }
};

export default PawnReducer