import {useEffect} from "react";
import moment from "moment";
import {useCustomNavigate} from "../../../core/utils/helper";
import {RouterName} from "../../../core/router";

const UserChatTabRowTemplate = (props: any) => {
    const navigate = useCustomNavigate()

    useEffect(() => {
    }, []);

    useEffect(() => {
    }, [props]);

    const renderCell = (key: any) => {
        switch (key) {
            case 'title':
                return <><h5>{props.data[key]}</h5><em className="slug">/{props.data.slug.alias}.html</em> </>
            case 'created_at':
            case 'updated_at':
                return <>{moment(props.data[key]).format('DD/MM/YYYY HH:mm')}</>
            default: return <>{props.data[key]}</>
        }
    }

    const handlePreview = () => {
        window.open(`/${props.data?.slug?.alias}.html`, '_blank')
    }
    const handleEdit = () => {
        navigate(RouterName.CONTENT_PAGE_EDIT.replace(/:id/g, props.data.id))
    }
    const handleDelete = () => {
        if(props.onDelete) {
            props.onDelete([props.data.id])
        }
    }

    return <tr>
        {
            props.columns.length > 0 && props.columns.map((col: any, k: number) => {
                if(col.state) return <td key={k}>{renderCell(col.key)}</td>
            })
        }
    </tr>
}
export default UserChatTabRowTemplate