import DataSet from "core/components/DataSet";
import { formNamePostStatistic } from "../hooks/useStatistics";
import module from "../module";
import UserStatisticListRowTemplate from "./UserStatisticListRowTemplate";
import PostStatisticListRowTemplate from "./PostStatisticListRowTemplate";

type PostStatisticTableProps = {
  queryParams: any;
  postStatistic: any
}

const PostStatisticTable = ({queryParams, postStatistic}: PostStatisticTableProps) => {
  return (
    <>
      <div className="row pt-2 mt-4">
        <div className="col-12">
          <strong className="fs-5">Post statistics ({postStatistic?.total > 0 ? postStatistic?.total : 0})</strong>
        </div>
      </div>
      <div className="row">
        <div className="col-12 post-statistic-response">
          <DataSet
            idKey={"hidden"}
            filterName={formNamePostStatistic}
            module={module.name}
            columnKey={formNamePostStatistic}
            pagination={{page: queryParams.get(`p_page`) || 1, page_size: queryParams.get(`p_page_size`) || 10}}
            columnTemplate={[
              { label: "ID", key: "id", state: true, sort: false },
              { label: "Name", key: "name", state: true, sort: false },
              {
                label: "Address",
                key: "address",
                state: true,
                sort: false,
              },
              {
                label: "Created By",
                key: "display_name",
                state: true,
                sort: false,
              },
              {
                label: "Total bids",
                key: "total_bids",
                state: true,
                sort: false,
              },
              {
                label: "Merchant Participants",
                key: "merchants",
                state: true,
                sort: false,
              },
              
              {
                label: "Status",
                key: "status",
                state: true,
                sort: false,
              },
              {
                label: "Created at",
                key: "created_at",
                state: true,
                sort: false,
              },
            ]}
            rowTemplate={PostStatisticListRowTemplate}
          />
        </div>
      </div>
    </>
  );
};

export default PostStatisticTable;
