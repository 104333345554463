export const FETCH_PAWN_LIST = "FETCH_PAWN_LIST"
export const FETCH_PAWN_LIST_SUCCESS = "FETCH_PAWN_LIST_SUCCESS"
export const FETCH_PAWN_LIST_FAIL = "FETCH_PAWN_LIST_FAIL"

export const INIT_PAWN_LIST = "INIT_PAWN_LIST"

export const FETCH_USER_DETAIL = "FETCH_USER_DETAIL"
export const FETCH_USER_DETAIL_SUCCESS = "FETCH_USER_DETAIL_SUCCESS"
export const FETCH_USER_DETAIL_FAILED = "FETCH_USER_DETAIL_FAILED"

export const FETCH_USER_POST = "FETCH_USER_POST"
export const FETCH_USER_POST_SUCCESS = "FETCH_USER_POST_SUCCESS"
export const FETCH_USER_POST_FAILED = "FETCH_USER_POST_FAILED"

export const FETCH_USER_COUPON = "FETCH_USER_COUPON"
export const FETCH_USER_COUPON_SUCCESS = "FETCH_USER_COUPON_SUCCESS"
export const FETCH_USER_COUPON_FAILED = "FETCH_USER_COUPON_FAILED"

export const FETCH_USER_CHAT = "FETCH_USER_CHAT"
export const FETCH_USER_CHAT_SUCCESS = "FETCH_USER_CHAT_SUCCESS"
export const FETCH_USER_CHAT_FAILED = "FETCH_USER_CHAT_FAILED"